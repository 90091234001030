
<template>


<div id="app">


<transition name="fade" mode="out-in" 
  enter-active-class="animate__animated animate__fadeIn" 
  leave-active-class="animate__animated animate__fadeOut"
>
<router-view/>
</transition>
</div>
</template>

<script>
  export default {

  }
</script>


<style lang="scss">
/* Animating Vue Router Transitions in Vue 3 */

.fade-enter-from,
.fade-leave-to {
  opacity: .5;
  transition: .7x cubic-bezier(0, 0, .58, 1);
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-out;
  position:fixed;
}

.transition-wipe {


}
html.is-animating .transition-wipe {
  transform: translateX(1);
}
.fade-enter-active, .fade-leave-active {
      width: inherit;
      transition: .1s;
  }

  .fade-enter, .fade-leave-to {
      opacity: 0;
  }
</style>